import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'

import moment from 'moment'
import {
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  AutoComplete,
  Spin,
  Tag,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  Checkbox,
  Badge,
  Radio,
  DatePicker,
} from 'antd'
import Request, {
  getLeadStageColor,
  getStatusStyle,
  getLeadTypeColor,
} from '../../../request'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper, getUrlParams } from '../../../routes'
import AddLead from '../add/modalIndex'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import { addLeadActivity } from './helpers' // CommonJs : require('react-on-screen').default

const { Paragraph } = Typography
const { TextArea } = Input
const { Option } = Select
let lanes = [
  {
    id: 'New (Untouched)',
    title: 'New (Untouched)',
    style: { width: 300, backgroundColor: '#f4f5f7', color: '#5243aa' },
    cardStyle: { maxWidth: 290 },
    cards: [],
  },
  {
    id: 'Contacted',
    title: 'Contacted',
    style: { width: 300, backgroundColor: '#f4f5f7', color: '#2196f3' },
    cardStyle: { maxWidth: 290 },
    cards: [],
  },
]

class AllLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
      allContacts: [],
      showBoard: false,
      showTable: true,
      currentPage: 1,
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      mobileList: [],
      selectedRowKeys: [],
      selectedRO: undefined,
      msgBody: '',
      msgType: 'SEND',
      lockUnlockLead: [],
      statusKey: '',
      stageKey: '',
      transferLeads: false,
      selectedLeadValue: '',
      selectedValues: [],

      leadsData: [],
      vaultLeadId: null,
      parentLeadId: null,
      vaultDateModal: false,
      newValueDate: null,
      selectedDate: null,
      
    }

    this.searchLead = _.debounce(this.handleDealsSearch, 100, true)
    let searchParams = getUrlParams('countUrl', window.location.pathname)
    let statesLeadsUrl = getUrlParams(
      'statesLeadsUrl',
      window.location.pathname
    )
    let officerStatesUrl = getUrlParams(
      'officerStatesUrl',
      window.location.pathname
    )
    let statusUrl = getUrlParams('statusUrl', window.location.pathname)

    if (statusUrl) {
      let { value, gte, lte } = statusUrl
      let key = ''
      if (value === 'won' || value === 'Lost' || value === 'Unqualified') {
        this.state.status = value
        this.state.gte = gte
        this.state.lte = lte

        switch (value) {
          case 'won':
            key = 'wonDate'
            break
          case 'Lost':
            key = 'lostDate'
            break
          case 'Unqualified':
            key = 'unqualifiedDate'
            break
        }

        this.state.statusKey = key
      }
      if (
        value === 'NewUntouched' ||
        value === 'Contacted'
      ) {
        this.state.stage = value === 'NewUntouched' ? 'New (Untouched)' : value
        this.state.gte = gte
        this.state.lte = lte

        if (gte == 'null') {
          this.state.lte = moment().endOf('day').toDate()
          delete this.state.gte
        }

        switch (value) {
          case 'NewUntouched':
            key = 'newUntouchedDate'
            break
          case 'Contacted':
            key = 'contactedDate'
            break
          
        }
        this.state.stageKey = key
      }
    }

    if (searchParams) {
      let { value, gte, lte, officerId } = searchParams
      if (value && value !== '0') {
        let key = ''
        if (value === 'won' || value === 'Lost') {
          this.state.status = value
          this.state.gte = gte
          this.state.lte = lte

          switch (value) {
            case 'won':
              key = 'wonDate'
              break
            case 'Lost':
              key = 'lostDate'
              break
          }

          this.state.statusKey = key
        }
        if (
          value === 'Contacted'
        ) {
          this.state.stage = value
          this.state.gte = gte
          this.state.lte = lte

          switch (value) {
            case 'Contacted':
              key = 'contactedDate'
              break
            
          }
          this.state.stageKey = key
        }
      }

      if (officerId) {
        this.state.officerId = officerId
        this.state.gte = gte
        this.state.lte = lte
      }
    }
    if (statesLeadsUrl) {
      let { gte, lte, value, stateName } = statesLeadsUrl
      let key = ''
      if (value === 'won' || value === 'Open' || value === 'Lost') {
        this.state.status = value
        switch (value) {
          case 'won':
            key = 'wonDate'
            break
          case 'Lost':
            key = 'lostDate'
            break
          case 'Open':
            key = 'createdAt'
            break
        }
      }
      this.state.statusKey = key
      this.state.gte = gte
      this.state.lte = lte
      this.state.stateName = stateName
    }
    if (officerStatesUrl) {
      let { gte, lte, value, stateName, officerId } = officerStatesUrl
      if (value === 'won' || value === 'Open' || value === 'Lost') {
        this.state.status = value
      } else if (value === 'all') {
        this.state.status = ['won', 'Open', 'Lost']
      }
      if (officerId) {
        this.state.officerId = officerId
      }

      this.state.gte = gte
      this.state.lte = lte
      this.state.stateName = stateName
    }

    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
  }

  deleteLead = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteLead(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }

  copyContent = ''
  handlePopConfirm = async (item) => {
    let { error } = await Request.updateMergeLead(item)
    if (!error) {
      notification.success({ message: 'Child Lead Removed Successfully' })
      this.table.current.reload()
    }
  }
  content = async (cardId) => {
    let x = await Request.getLeadById({ _id: cardId })
    if (!x.error) {
      this.setState({
        leadData: x.data,
      })
      if (!x.data.contact) {
        //  return "No number found";
        this.copyContent = 'No number found'
      } else {
        // return <div>
        //   <Paragraph copyable>{x.data.contact.mobile}</Paragraph>
        // </div>
        this.copyContent = (
          <div>
            <Paragraph copyable>{x.data.contact.mobile}</Paragraph>
          </div>
        )
      }
    }
  }
  handleRemove = (value) => {
    const { selectedValues } = this.state
    const newValues = _.filter(
      this.state.selectedValues,
      (val) => val._id !== value
    )

    this.setState({ selectedValues: newValues })
  }
  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    let { contactNameId } = this.state

    // if (!params.stage || !params.stage.length) {
    //   params.stage = this.state.leadStage
    // }

    if (this.state.selectedLeadStage) {
      params.stage = this.state.selectedLeadStage
    }

    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    let user = { ...JSON.parse(localStorage.getItem('user')) }
    let { status, statusKey, stageKey, stage, stateName } = this.state
    return new Promise(async (resolve) => {
      if (status && statusKey && !stateName) {
        params.status = status
        params[statusKey] = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }
      if (stage && stageKey) {
        params.stage = stage
        params[stageKey] = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }

      //   if (!params.status || !params.status.length) {
      //     params.status = ['Open', 'won', 'Lost', 'Unqualified']
      //   }

      if (stateName) {
        params.state = {
          $in: [stateName.toLowerCase(), _.startCase(stateName)],
        }

        params.state =
          stateName && stateName == 'Jammu Kashmir'
            ? 'Jammu & Kashmir'
            : stateName

        params[statusKey] = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
        params.status = status
      }

      if (this.state.unassignedLeads) {
        params.officer = { $in: [null] }
      }
      if (params.results && params.page) {
        this.setState({ lockUnlockLead: [] })
      }

      if (user && user.userType == 'Manager' && !this.state.officerId) {
        if (!params.officer || !params.officer.length) {
          params.officer = user && user.roList
        }
      }

      if (this.state.transferLeads) {
        params.isTransferred = true
      }
      params.isChild = false
      params.isVault = false
      this.setState({ filters: { ...params } })
      let data = await Request.getAllLeads({
        ...params,
        customQuery: {
          contact: contactNameId ? contactNameId : undefined,
          officer: this.state.officerId ? this.state.officerId : undefined,
          //   $or: [
          //     { leadApprovedByAdmin: { $in: [true] } },
          //     { leadApprovedByAdmin: { $exists: false } },
          //   ],
        },
        regExFilters: ['title', 'company', 'contact', 'campaignName'],
      })
      _.each(data.data, (item, i) => {
        if (item.isLocked) {
          this.setLockUnlockLead(i, item.isLocked)
        }
      })
      resolve(data)
      this.setState({ leadData: data, count: data.total })
    })
  }
  handleDealsSearch = async (test) => {
    let obj = {}
    this.setState({ showSpin: true })

    if (test === '') {
      return false
    }
    obj.dontCheckStatus = true
    obj.text = test
    obj.isChild = true
    let { data, error, message } = await Request.leadsSearch(obj)
    if (!error) {
      this.setState({ leadsData: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }
  getTodos = async (stageArr) => {
    let newCards = []
    let { contactNameId } = this.state
    if (this.state.selectedLeadStage) {
      this.state.filters.stage = this.state.selectedLeadStage
    }

    let { data: data, total: totalCount } = await Request.getAllLeads({
      ...this.state.filters,
      customQuery: {
        contact: contactNameId ? contactNameId : undefined,
        officer: this.state.officerId ? this.state.officerId : undefined,
        // $or: [
        //     { leadApprovedByAdmin: { $in: [true] } },
        //     { leadApprovedByAdmin: { $exists: false } },
        //   ],
      },
    })

    this.setState({ count: totalCount })

    _.each(data, (item, key) => {
      let found = _.find(lanes, (lane) => lane.title == item.stage)
      let description = (
        <div>
          {item.contact &&
            `${item.contact.firstName} ${
              item.contact.lastName ? item.contact.lastName : ''
            }`}
          <br />
          {item.company}
          <br />
          <div style={{ display: 'block' }}>
            <span>
              <Tooltip title={'Send an Email'}>
                <Button
                  icon={'mail'}
                  type={'link'}
                  className={styles.leadbtn}
                />
              </Tooltip>
              <Tooltip title={'Number'}>
                {/* <Popover content={this.copyContent} trigger="click"> */}
                <Button
                  style={{ zIndex: 100 }}
                  icon={'phone'}
                  type={'link'}
                  onClick={(e) => {
                    this.content(this.state.cardId)
                    this.setState({ visible: false, updatelead: false })
                  }}
                  className={styles.leadbtn}
                />
                {/* </Popover> */}
              </Tooltip>
              <Tooltip style={{ position: 'absolute' }} title={'Activity'}>
                <Button
                  icon={'info-circle'}
                  type={'link'}
                  className={styles.leadbtn}
                />
              </Tooltip>
              <Tooltip title={'View'}>
                <Button
                  icon={'eye'}
                  type={'link'}
                  onClick={() => {
                    this.props.dispatch(
                      getUrlPushWrapper('lead.viewLead', {
                        id: item._id,
                      })
                    )
                  }}
                  className={styles.leadbtn}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      )
      let label = (
        <div>
          <Tooltip title={item.contact ? item.contact.firstName : ''}>
            <Avatar
              style={{
                backgroundColor: '#03a9f4',
              }}>
              {item && item.contact && item.contact.firstName
                ? item.contact.firstName.charAt(0).toUpperCase()
                : '?'}
            </Avatar>
          </Tooltip>
        </div>
      )
      if (found) {
        let bb = {}
        bb.id = item._id.toString()
        bb.title = item.title
        bb.description = description
        bb.label = label
        found.cards.push(bb)
        newCards.push(bb)
        found.label = found.cards.length
      } else {
        let aa = {}
        aa.id = Math.random()
        aa.title = item.stage
        aa.style = { width: 280 }
        aa.cards = [
          {
            id: item._id.toString(),
            title: item.title,
            description: description,
            label: label,
          },
        ]
        aa.label = aa.cards.length
        lanes.push(aa)
      }
    })

    let data1 = {}
    data1.lanes = lanes
    this.setState({ boardData: data1 })
  }

  showDarwer = () => {
    this.setState({
      drawerVisible: true,
      btnLoader: false,
    })
  }
  updateVaultDate = async () => {
    const params = {}
    params._id = this.state.vaultLeadId
    params.vaultDate = this.state.selectedDate
    const { error } = await Request.updateVaultDate(params)
    if (!error) {
      notification.success({ message: 'Vault Date Updated' })
      this.setState(
        {
          vaultDateModal: false,
        },
        () => {
          this.table.current.reload()
        }
      )
    } else {
      notification.warning({ message: 'Error' })
      this.setState({
        vaultDateModal: false,
      })
    }
  }
  showModal = () => {
    this.setState({
      visible: true,
      btnLoader: false,
    })
  }
  waSave = async () => {
    const { msgType, msgBody, selectedRows, selectedRowKeys, waMobile } =
      this.state
    if (!msgBody) {
      return notification.error({ message: 'Enter Message' })
    }
    if (!waMobile.length) {
      return notification.error({ message: 'Select Number' })
    }
    let obj = {
      type: msgType,
      messageBody: msgBody,
      waMobile: waMobile,
    }
    this.setState({ waLoader: true })

    let { data, error, message } = await Request.waDetailSave(obj)

    this.setState({ waLoader: false })

    if (!error) {
      this.setState({
        waMobile: [],
        whatsappData: false,
        selectedRows: undefined,
        msgBody: '',
        msgType: 'SEND',
        selectedRowKeys: [],
      })
      notification.success({ message: message })
      this.table.current.reload()
    } else {
      notification.error({ message: message })
    }
  }
  getLeadsForAutoComplete = () => {
    Request.getAllLeads({
      sortField: 'createdAt',
      sortOrder: 'desc',
      results: 10,
      parentLeadId: this.state.parentLeadId,
      isChild: false,
    }).then(({ data: leadNames }) => {
      this.setState({ leadsData: leadNames })
    })
  }
  mergeLeadsToParent = async () => {
    let childLeadsArray = []
    _.map(this.state.selectedValues, (item) =>
      childLeadsArray.push({ childLeadId: item._id, childLeadNumber: item.id })
    )

    const params = {
      parentLeadId: this.state.parentLeadId,
      childLeadsArray: childLeadsArray,
    }
    const { error } = await Request.mergeLeads(params)
    if (!error) {
      notification.success({ message: 'Lead Merged Successfully' })

      this.setState({
        selectedValues: [],
        selectedLeadValue: '',
        drawerVisible: false,
      })
      this.table.current.reload()
      this.getLeadsForAutoComplete()
    } else {
      notification.error({ message: 'Error Merging' })
    }
  }
  assignLeads = async () => {
    const { selectedRowKeys, selectedRO } = this.state
    if (selectedRowKeys.length === 0 || !selectedRO) {
      return
    }
    this.setState({ assignLoader: true })
    let { data, error, message } = await Request.assignLeads(selectedRowKeys, {
      officer: selectedRO,
    })
    this.setState({ assignLoader: false })

    if (!error) {
      let foundRO = _.find(this.state.RoList, (item) => {
        return item._id === selectedRO
      })
      if (foundRO) {
        _.each(selectedRowKeys, async (item) => {
          await addLeadActivity({
            values: {
              message: `Lead assign to ${foundRO.name}`,
              leadId: item,
              user: this.props.currentUser.name,
              type: 'info',
            },
          })
        })
      }

      notification.success({ message: message })
      this.table.current.reload()
      this.setState({
        selectedRO: undefined,
        assignLeadDrawer: false,
        selectedRowKeys: [],
      })
    } else {
      notification.error({ message: message })
    }
  }

  changeLeadStatus = async () => {
    const { selectedRowKeys, selectedStatus } = this.state
    if (selectedRowKeys.length === 0 || !selectedStatus) {
      return
    }
    this.setState({ statusLoader: true })
    let { data, error, message } = await Request.updateMultipleLeadStatus(
      selectedRowKeys,
      { status: selectedStatus }
    )
    this.setState({ statusLoader: false })

    if (!error) {
      notification.success({ message: message })
      this.table.current.reload()
      this.setState({
        selectedStatus: undefined,
        statusChangeDrawer: false,
        selectedRowKeys: [],
      })
    } else {
      return notification.error({ message: message })
    }
  }
  transferLeadToExistingRO = async () => {
    const { selectedRowKeys, selectedStatus } = this.state
    if (selectedRowKeys.length === 0) {
      return
    }
    this.setState({ transferLoader: true })
    let { data, error, message } = await Request.transferLeadToExistingRO(
      selectedRowKeys
    )
    this.setState({ transferLoader: false })

    if (!error) {
      notification.success({ message: message })
      this.table.current.reload()
      this.setState({ selectedRowKeys: [] })
    } else {
      return notification.error({ message: message })
    }
  }

  async componentDidMount() {
    this.props.dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
    //        let {data: allContacts} = await Request.getAllContacts();
    //this.getTodos()
    // this.setState({allContacts, sale: 'Sales'})
    this.getLeadsForAutoComplete()
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType == 'Manager') {
      let { data: allContacts } = await Request.getAllContacts({
        contactOwner: { $in: user.roList },
      })

      Request.loadUser(
        { _id: { $in: user.roList } },
        { results: 1000000000000 }
      ).then(({ data: userList }) => {
        this.setState({ RoList: userList, allContacts })
      })
    } else {
      let { data: allContacts } = await Request.getAllContacts()

      let { data: userList } = await Request.getAllROList({
        customQuery: { userType: 'RO' },
      })
      this.setState({ RoList: userList, allContacts })
    }
    this.getTodos()
  }

  handleContactSearch = async (test) => {
    let filter = {}
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )
      return false
    }

    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType == 'Manager') {
      filter.roList = user && user.roList
    }

    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
      ...filter,
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current && this.table.current.reload()
        }
      )
    }
  }

  setLockUnlockLead = (key, value, leadId) => {
    let { lockUnlockLead } = this.state
    let newValue = _.clone(lockUnlockLead)
    newValue[key] = value
    this.setState({ lockUnlockLead: newValue }, () => {
      if (leadId) this.updateTheLead({ _id: leadId, isLocked: value })
    })
  }

  updateTheLead = async (values) => {
    let x = await Request.updateLead(values)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
    } else {
      console.log('err is', x.error)
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }
  exportData = async () => {
    let { contactNameId } = this.state

    if (this.state.selectedLeadStage) {
      this.filters.stage = this.state.selectedLeadStage
    }

    this.setState({ startLoading: true })
    let data = await Request.getAllLeads({
      ...this.state.filters,
      export: true,
      customQuery: {
        contact: contactNameId ? contactNameId : undefined,
        officer: this.state.officerId ? this.state.officerId : undefined,
      },
    })
    this.setState({ startLoading: false })
    if (!data.error) {
      return notification.success({ message: 'Mail Sent' })
    } else {
      return notification.error({ message: 'Error' })
    }
  }

  render() {
    const RofilterArray =
      this.state.RoList &&
      this.state.RoList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })
   

    const { submitting, currentUser, dispatch } = this.props
    const {
      leadStatuseArr,
      allContacts,
      showTable,
      showBoard,
      leadStage,
      boardData,
      selectedRowKeys,
      RoList,
      assignLeadDrawer,
      whatsappData,
      selectedRO,
      msgBody,
      msgType,
      assignLoader,
      waLoader,
      currentPage,
      selectedStatus,
      waMobile,
      selectedRows,
      mobileList,
      waMessage,
      statusLoader,
    } = this.state

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 100,
        render: (text, record) => {
          if (!record.isVault) {
            return (
              <React.Fragment>
                <Tooltip title="View">
                  <a
                    href={`/lead/detail?id=${record && record._id}`}
                    target={'_blank'}>
                    <Button
                      style={{ marginLeft: '5px' }}
                      size="small"
                      icon={'eye'}
                      shape={'circle'}
                    />
                  </a>
                </Tooltip>
                <Tooltip title="Merge Lead">
                  <Button
                    style={{ marginLeft: '6px', marginTop: '6px' }}
                    size="small"
                    shape="circle"
                    onClick={() => {
                      this.setState(
                        {
                          selectedValues: [],
                          selectedLeadValue: null,
                          parentLeadId: record._id,
                          singleRecord: record,
                        },
                        () => {
                          this.getLeadsForAutoComplete()
                          this.showDarwer()
                        }
                      )
                    }}
                    icon="diff"
                  />
                </Tooltip>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                <Tooltip title="View">
                  <a
                    href={`/lead/detail?id=${record && record._id}`}
                    target={'_blank'}>
                    <Button
                      style={{ marginLeft: '5px' }}
                      size="small"
                      icon={'eye'}
                      shape={'circle'}
                    />
                  </a>
                </Tooltip>
              </React.Fragment>
            )
          }
        },
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        searchTextName: 'id',
        width: 100,
      },

      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                this.setState({
                  ...this.state,
                  cardId: record._id,
                  updatelead: true,
                })
              }}>
              {record.title}
            </a>
          </span>
        ),
        // width: 150
      },
      {
        title: 'Campaign',
        dataIndex: 'campaignName',
        key: 'campaignName',
        searchTextName: 'campaignName',
      },
      // {
      //   title: 'Child Leads',
      //   // dataIndex: 'leadType',
      //   key: 'childLead',
      //   searchTextName: 'Child',
      //   render: (val, record) => {
      //     if (record.childLeadsArray && record.childLeadsArray.length > 0) {
      //       return (
      //         <div style={{ display: 'flex', flexDirection: 'column' }}>
      //           {_.map(record.childLeadsArray, (item) => {
      //             return (
      //               <>
      //                 <div
      //                   style={{
      //                     display: 'inline-block',
      //                     // backgroundColor: 'rgb(211 202 202)',
      //                     textAlign: 'center',
      //                     marginTop: '10px',
      //                   }}>
      //                   <a
      //                     href={`/lead/detail?id=${item.childLeadId}`}
      //                     target={'f'}>
      //                     {item.childLeadNumber}
      //                   </a>

      //                   <Popconfirm
      //                     title="Are you sure you want to remove this Child?"
      //                     // visible={visible}
      //                     onCancel={() => {}}
      //                     onConfirm={() => this.handlePopConfirm(item)}
      //                     okText="Yes"
      //                     cancelText="No">
      //                     <Button
      //                       icon="close"
      //                       type="link"
      //                       style={{
      //                         height: '24px',
      //                         color: 'red',
      //                         marginLeft: '10px',
      //                       }}></Button>
      //                   </Popconfirm>
      //                 </div>
      //               </>
      //             )
      //           })}
      //         </div>
      //       )
      //       //   <div>{record.childLeadsArray.length}</div>
      //     } else {
      //       return <div>0</div>
      //     }
      //   },
      // },

      // {
      //   title: 'Vault',
      //   dataIndex: 'isVault',
      //   key: 'isVault',
      //   filters:[{ text: 'Yes', value: true },
      //   { text: 'No', value: false }],
      //   render: (text, record) => {
      //     if (!record.isVault) {
      //       return <div>{'No'}</div>
      //     } else {
      //       return <div>{'Yes'}</div>
      //     }
      //   },
      // },

      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
        // width: 160
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
        // width: 100
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        filters: RofilterArray,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
            </div>
          )
        },
        width: 140,
      },

      {
        title: 'Stage',
        //  dataIndex: 'stage',
        key: 'stage',
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: getLeadStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
        filters: _.map(this.state.leadStage, (ii) => ({ text: ii, value: ii })),
      },
      {
        title: 'Stage Date',
        key: 'contactedDate',
        width: 130,
        render: (text, record) => {
          if (record && record.stage == 'Contacted') {
            return (
              <div>
                {record && record.contactedDate
                  ? moment(record.contactedDate).format('lll')
                  : ''}
              </div>
            )
          } else if (record && record.stage == 'New (Untouched)') {
            return (
              <div>
                {record && record.newUntouchedDate
                  ? moment(record.newUntouchedDate).format('lll')
                  : ''}
              </div>
            )
          }
        },
      },
      {
        title: 'Lead Type',
        dataIndex: 'leadType',
        key: 'leadType',
        filters: [
          { text: 'IndiaMART Api', value: 'IndiaMART Api' },
          { text: 'Inbound', value: 'Inbound' },
          { text: 'Aajjo', value: 'aajjo' },
          { text: 'Facebook Ads', value: 'Facebook Ads' },
        ],
        render: (val, record) => {
          return (
            <div>
              {record.leadType ? (
                <div style={getLeadTypeColor(record.leadType)}>
                  {record.leadType}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
        // width: 100
      },
      {
        title: 'Total Call Logs',
        dataIndex: 'callLogs',
        key: 'callLogs',
        render: (val, record) => {
          return (
            <div>
              <div>
                <small>
                  Outgoing:{' '}
                  {val &&
                    (val.outgoingCalls || 0) + (val.outgoingRejectCalls || 0)}
                </small>
              </div>
              <div>
                <small>Incoming: {(val && val.incomingCalls) || 0}</small>
              </div>
              <div>
                <small>Missed: {(val && val.missedCalls) || 0}</small>
              </div>
              <div>
                <small>Attended: {(val && val.totalAttended) || 0}</small>
              </div>
            </div>
          )
        },
        // width: 100
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
        // width: 100
      },

      {
        title: 'Status',
        key: 'status',
        filters:
          leadStatuseArr && leadStatuseArr.map((x) => ({ value: x, text: x })),
        // width: 130,
        render: (text, record) => {
          return (
            <div>
              {record.status ? (
                <div style={getStatusStyle(record.status)}>{record.status}</div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Status Date',
        key: 'wonDate',
        width: 130,
        render: (text, record) => {
          if (record && record.status == 'won') {
            return (
              <div>
                {record && record.wonDate
                  ? moment(record.wonDate).format('lll')
                  : ''}
              </div>
            )
          } else if (record && record.status == 'Lost') {
            return (
              <div>
                {record && record.lostDate
                  ? moment(record.lostDate).format('lll')
                  : ''}
              </div>
            )
          } else if (record && record.status == 'Unqualified') {
            return (
              <div>
                {record && record.unqualifiedDate
                  ? moment(record.unqualifiedDate).format('lll')
                  : ''}
              </div>
            )
          }
        },
      },
      {
        title: 'Tags',
        key: 'tags',
        width: 140,
        render: (text, record) => {
          if (record.tags && record.tags.length) {
            return _.map(record.tags, (ii) => {
              return <Tag style={{ marginBottom: '2px' }}>{ii}</Tag>
            })
          }
        },
        filters: [{ text: 'No Future FollowUp', value: 'No Future FollowUp' }],
      }, 

      {
        title: 'Latest Followup',
        dataIndex: 'latestFollowup',
        key: 'latestFollowup',
        render: (val, record) => {
          return (
            <div>
              <div>
                {record && record.description ? record.description : ''}
              </div>
              <div>
                {record && record.overdueCount ? (
                  <Tag
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                    }}>{`Overdue (${record.overdueCount})`}</Tag>
                ) : (
                  ''
                )}
              </div>
              <div>
                {record && record.followUpDate
                  ? moment(record.followUpDate).format('ll')
                  : ''}
              </div>
            </div>
          )
        },
        width: 180,
      },
      // {
      //   title: 'Vault Date',
      //   dataIndex: 'vaultDate',
      //   key: 'vaultDate',
      //   searchDateName: 'vaultDate',
      //   sorter: true,
      //   // fixed: 'right',
      //   render: (val, record) => {
      //     if (record && record.vaultDate) {
      //       return (
      //         <div>
      //           <div
      //             style={{
      //               textAlign: 'center',
      //               color: 'red',
      //               padding: '2px 2px',
      //               borderRadius: '2px',
      //               minWidth: '30px',
      //             }}>
      //             {' '}
      //             {record && record.vaultDate ? (
      //               <div>
      //                 <Tooltip
      //                   title={`${moment(record.vaultDate).format('ll')}`}>
      //                   {' '}
      //                   {moment(record.vaultDate).diff(moment(), 'days')} days
      //                   left
      //                 </Tooltip>
      //                 <Button
      //                   style={{ marginLeft: '6px', marginTop: '6px' }}
      //                   size="small"
      //                   shape="circle"
      //                   onClick={() => {
      //                     this.setState({
      //                       vaultLeadId: record._id,
      //                       selectedDate: record.vaultDate,
      //                       vaultDateModal: true,
      //                     })
      //                   }}
      //                   icon="edit"
      //                 />
      //               </div>
      //             ) : (
      //               'No Vault Date'
      //             )}{' '}
      //           </div>
      //         </div>
      //       )
      //     }
      //   },
      //   width: 150,
      // },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        searchDateName: 'createdAt',
        render: (val, record) => {
          return (
            <div>
              <div>{moment(record.createdAt).format('lll')}</div>
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        searchDateName: 'updatedAt',
        render: (val, record) => {
          return (
            <div>
              <div>{moment(record.updatedAt).format('lll')}</div>
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
      {
        title: 'Lock/Unlock',
        dataIndex: 'isLocked',
        key: 'isLocked',
        fixed: 'right',
        filters: [
          { text: 'Locked', value: true },
          { text: 'UnLocked', value: false },
        ],

        render: (val, record, key) => {
          if (!record.isVault) {
            return (
              <Switch
                checked={this.state.lockUnlockLead[key]}
                onChange={(vall) => {
                  this.setLockUnlockLead(key, vall, record && record._id)
                }}
              />
            )
          }else{
            return (
              <div></div>
            )
          }
        },
        width: 80,
      },
    ]

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
        this.setState({ selectedRows })

        let mobArr = selectedRows.map((item, ii) => {
          return (
            <Option key={ii} value={item.contact && item.contact.mobile}>
              {item.contact && item.contact.mobile}
            </Option>
          )
        })
        this.setState({
          waMobile: selectedRows.map((item) => item.contact && item.contact.mobile),
        })
        this.setState({ mobileList: mobArr })
      },
      getCheckboxProps: (record) => ({
        _id: record._id,
      }),
    }

    const unassignedLeadsFilterChange = (e) => {
      if (e.target.checked) {
        this.setState({ unassignedLeads: true }, () => {
          this.table.current.reload()
        })
      } else {
        this.state.unassignedLeads = false
        this.table.current.reload()
      }
    }

    const TransferLeadsFilterSet = (e) => {
      if (e.target.checked) {
        this.setState({ transferLeads: true }, () => {
          this.table.current.reload()
        })
      } else {
        this.state.transferLeads = false
        this.table.current.reload()
      }
    }
    if (this.state.transferLeads) {
      let dataItem = {
        title: 'Existing RO',
        key: 'existingRO',
        render: (text, record) => {
          return (
            <div>
              <div>{record && record.existingRO && record.existingRO.name}</div>
              <small>
                {record && record.existingRO && record.existingRO.email}
              </small>
              <small>
                {record && record.existingRO && record.existingRO.mobile}
              </small>
            </div>
          )
        },
      }
      columns.splice(7, 0, dataItem)
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count ? `All Leads : ${this.state.count}` : `All Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={5}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={this.state.selectedLeadStage}
                  onChange={(id) => {
                    if (id == undefined) {
                      this.setState({ selectedLeadStage: '' }, () => {
                        this.getTodos()
                        this.table.current
                          ? this.table.current.reload()
                          : console.log('')
                      })
                    } else {
                      this.setState({ selectedLeadStage: id }, () => {
                        this.getTodos([id])
                        this.table.current
                          ? this.table.current.reload()
                          : console.log('')
                      })
                    }
                  }}>
                  {leadStage &&
                    leadStage.map((val, idn) => {
                      return (
                        <Option key={idn} value={val}>
                          {val}
                        </Option>
                      )
                    })}
                </Select>
              </Col>

              <Col span={7}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>

              <Col span={4}>
                <Checkbox onChange={unassignedLeadsFilterChange}>
                  Unassigned Leads
                </Checkbox>
              </Col>
              <Col span={2}>
                <Checkbox onChange={TransferLeadsFilterSet}>
                  Transferred Leads
                </Checkbox>
              </Col>

              <Col span={6} style={{ textAlign: 'end' }}>
                <Tooltip title={'List'}>
                  <Button
                    type={'default'}
                    className={styles.defaultBtn}
                    onClick={() => {
                      this.setState({ showTable: true, showBoard: false })
                    }}>
                    List
                  </Button>
                </Tooltip>
                <Tooltip title={'Board'}>
                  <Button
                    type={'primary'}
                    className={styles.btnPrimary}
                    onClick={() => {
                      this.setState({ showBoard: true, showTable: false })
                    }}>
                    Board
                  </Button>
                </Tooltip>
                <Button
                  type={'primary'}
                  onClick={() => {
                    this.showModal()
                  }}>
                  <Icon type={'plus'} />
                  Add Lead
                </Button>
              </Col>
            </Row>
          </Card>

          <Card className={styles.outerLeadCard}>
            {showTable && (
              <>
                <div className={styles.exportDiv}>
                  <div className={styles.assignBtn}>
                    <Button
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => this.setState({ whatsappData: true })}>
                      Whatsapp <Icon type="right" />
                    </Button>
                  </div>
                  <div className={styles.assignBtn}>
                    <Button
                      loading={this.state.transferLoader}
                      style={{ marginRight: '10px', marginLeft: '10px' }}
                      onClick={async () => {
                        this.setState({ transferLoader: true })
                        let { data, error, message } =
                          await Request.transferLeadToExistingRO()
                        this.setState({ transferLoader: false })
                        if (!error) {
                          notification.success({ message: message })
                          this.table.current.reload()
                        } else {
                          return notification.error({ message: message })
                        }
                      }}>
                      Transfer All Leads <Icon type="right" />
                    </Button>
                  </div>

                  <div className={styles.assignBtn}>
                    <Button
                      disabled={selectedRowKeys.length === 0}
                      onClick={() => this.setState({ assignLeadDrawer: true })}>
                      Assign To <Icon type="right" />
                    </Button>
                  </div>
                  <div className={styles.assignBtn}>
                    <Button
                      style={{ marginLeft: '10px' }}
                      disabled={selectedRowKeys.length === 0}
                      onClick={() =>
                        this.setState({ statusChangeDrawer: true })
                      }>
                      Change Status <Icon type="right" />
                    </Button>
                  </div>
                  <div className={styles.assignBtn}>
                    <Button
                      loading={this.state.transferLoader}
                      style={{ marginLeft: '10px' }}
                      disabled={
                        this.state.transferLeads && selectedRowKeys.length != 0
                          ? false
                          : true
                      }
                      onClick={() => this.transferLeadToExistingRO()}>
                      Transfer To Existing RO <Icon type="right" />
                    </Button>
                  </div>
                  <div className={styles.assignBtn}>
                    <Button
                      style={{ marginBottom: 10, marginLeft: 10 }}
                      type="default"
                      onClick={() => {
                        this.exportData()
                      }}
                      loading={this.state.startLoading}>
                      Export Excel
                    </Button>
                  </div>
                </div>
                <div style={{ backgroundColor: 'white' }}>
                  <TableComp
                    ref={this.table}
                    columns={columns}
                    apiRequest={this.apiRequest}
                    extraProps={{
                      scroll: { x: 3000 },
                      rowSelection: { ...rowSelection },
                      rowClassName: (record, index) => {
                        if (record && record.status == 'won') {
                          return styles.wonColor
                        } else if (record && record.status == 'Lost') {
                          return styles.lostColor
                        } else if (record && record.status == 'Unqualified') {
                          return styles.unqualifiedColor
                        } else {
                          return styles.openColor
                        }
                      },
                    }}
                    id="leadList"
                    pagination={{
                      ...this.state.pagination,
                      defaultPageSize: 10,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      showSizeChanger: true,
                      ...this.props.pagination,
                    }}
                  />
                </div>
              </>
            )}

            {showBoard && (
              <Board
                data={boardData}
                laneDraggable={false}
                className={styles.board}
                cardDraggable
                style={{ backgroundColor: '#f4f5f7' }}
                key={this.state.key}
                onCardClick={(cardId, metadata, laneId) => {
                  this.setState({
                    updatelead: true,
                    cardId,
                    drawerKey: Date.now(),
                  })
                }}
                handleDragEnd={async (
                  cardId,
                  sourceLaneId,
                  targetLaneId,
                  position,
                  cardDetails
                ) => {
                  let obj = { _id: cardId, stage: targetLaneId }
                  let x = await Request.changeLeadStage(obj)
                  if (!x.error) {
                    notification.success({ message: x.message })
                  } else {
                    notification.error({ message: x.message || 'Error Saving' })
                  }
                }}
              />
            )}
          </Card>
        </div>
        <Modal
          width={1000}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          maskClosable={true}
          centered={true}
          footer={null}
          keyboard={true}
          title={'Add New Lead'}
          // icon={<QuestionCircle />}
        >
          <AddLead
            allContacts={allContacts}
            handleAddLead={() => {
              this.setState({ visible: false })
              this.table.current.reload()
              this.getTodos()
            }}
          />
        </Modal>
        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          {/* cardId={cardId} */}
          <UpdateLead
            cardId={this.state.cardId}
            allContacts={allContacts}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.setState({ updatelead: false, cardId: '' })
              this.getTodos()
            }}
          />
        </Drawer>
        <Modal
          title="Whatsapp Data"
          onCancel={async () => {
            this.setState({
              whatsappData: false,
              msgBody: null,
            })
          }}
          visible={whatsappData}
          okText="SAVE"
          onOk={this.waSave}
          okButtonProps={{
            loading: waLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Row>
                <Form.Item label="Message">
                  <TextArea
                    rows={4}
                    style={{ width: 210 }}
                    onChange={(e) =>
                      this.setState({
                        msgBody: e.target.value,
                      })
                    }
                    value={msgBody}
                    placeholder="Write Message"
                    required
                  />
                </Form.Item>
              </Row>
            </Form>
            <Form layout="inline">
              <Row>
                <Col>
                  <Form.Item label="Mobile No." style={{ width: '100%' }}>
                    <Select
                      mode="tags"
                      style={{ width: 250 }}
                      placeholder="Mobile"
                      onChange={(value) => {
                        this.setState({
                          waMobile: value,
                        })
                      }}
                      value={waMobile}>
                      {this.state.mobileList}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Assign Leads"
          onCancel={async () => {
            this.setState({
              assignLeadDrawer: false,
              selectedRO: undefined,
            })
          }}
          visible={assignLeadDrawer}
          okText="Assign"
          onOk={this.assignLeads}
          okButtonProps={{
            loading: assignLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="RO Officer">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={selectedRO}
                  onChange={(selectedRO) => {
                    this.setState({ selectedRO })
                  }}
                  placeholder="Select RO Officer">
                  {RoList &&
                    RoList.map((val, id) => {
                      return (
                        <Option
                          key={val._id}
                          value={
                            val._id
                          }>{`${val.name} (${val.email})`}</Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Change Leads Status"
          onCancel={async () => {
            this.setState({
              statusChangeDrawer: false,
              selectedStatus: undefined,
            })
          }}
          visible={this.state.statusChangeDrawer}
          okText="Change"
          onOk={this.changeLeadStatus}
          okButtonProps={{
            loading: statusLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="Status">
                <Select
                  showSearch
                  // allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={selectedStatus}
                  onChange={(selectedStatus) => {
                    this.setState({ selectedStatus })
                  }}
                  placeholder="Select Status">
                  {leadStatuseArr &&
                    leadStatuseArr.map((val, id) => {
                      return (
                        <Option key={val} value={val}>
                          {val}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Drawer
          title=""
          placement="right"
          // maskClosable={false}
          closable={true}
          onClose={async () => {
            this.setState({
              drawerVisible: false,
              selectedValues: [],
              selectedLeadValue: null,
            })
            this.getLeadsForAutoComplete()
            this.setState({
              selectedValues: [],
              selectedLeadValue: null,
            })
          }}
          visible={this.state.drawerVisible}
          width={800}>
          <Card>
            <Row>
              <Col span={8}>
                <AutoComplete
                  notFoundContent={this.state.showSpin && <Spin />}
                  value={this.state.selectedLeadValue}
                  autoComplete={'none'}
                  onSelect={(v) => {
                    if (
                      !_.find(
                        this.state.selectedValues,
                        (item) => item._id === v
                      ) &&
                      v !== this.state.parentLeadId
                    ) {
                      let selectedLead = this.state.leadsData.find((obj) => {
                        if (obj._id === v) {
                          return { _id: v, leadNumber: obj.id }
                        }
                      })

                      this.setState({
                        selectedValues: [
                          ...this.state.selectedValues,
                          selectedLead,
                        ],
                        // selectedLeadValue: selectedLead.title,
                        selectedLeadValue: selectedLead.id,
                      })
                    } else {
                      this.setState({
                        selectedLeadValue: 'Already Exist',
                      })
                    }
                  }}
                  style={{ width: '300px' }}
                  onSearch={this.searchLead}
                  placeholder={'Lead Name'}
                  onChange={(v) => this.setState({ selectedLeadValue: v })}>
                  {this.state.leadsData.map((val, item) => (
                    <Option value={val._id} key={val._id}>
                      <>
                        <div>
                          <b>{`${val.title} (${val.id})`}</b>
                        </div>
                        <div
                          className={styles.autoCompleteList}
                          style={{ marginTop: '0px' }}>{`${
                          val.contact && val.contact.email
                            ? val.contact.email
                            : ''
                        }`}</div>
                        <div className={styles.autoCompleteList}>{`${
                          val.contact && val.contact.firstName
                        } (${val.contact && val.contact.mobile})`}</div>
                        <div className={styles.autoCompleteList}>{`Company: ${
                          val.company || ''
                        }`}</div>
                        <Tag>{`${moment(val.createdAt).format(
                          'DD-MM-YYYY HH:mm'
                        )}`}</Tag>
                      </>
                    </Option>
                  ))}
                </AutoComplete>
                <div>
                  <div
                    style={{
                      display: 'flex',

                      flexWrap: 'wrap',
                      width: '800px',

                      // m   ax-width:"700px",
                    }}>
                    {this.state.selectedValues.map((value) => (
                      <Tag
                        key={value._id}
                        style={{
                          width: '230px',
                          height: '130px',
                          margin: '8px',
                        }}
                        //   onClose={() => this.handleRemove(value._id)}
                      >
                        <div>
                          <b>{`${value.title} (${value.id})`}</b>
                        </div>
                        <div
                          className={styles.autoCompleteList}
                          style={{ marginTop: '0px' }}>{`${
                          value.contact && value.contact.email
                            ? value.contact.email
                            : ''
                        }`}</div>
                        <div className={styles.autoCompleteList}>{`${
                          value.contact && value.contact.firstName
                        } (${value.contact && value.contact.mobile})`}</div>
                        <div className={styles.autoCompleteList}>{`Company: ${
                          value.company || ''
                        }`}</div>
                        <Tag>{`${moment(value.createdAt).format(
                          'DD-MM-YYYY HH:mm'
                        )}`}</Tag>
                        <Button
                          style={{ color: 'red' }}
                          icon={'cross'}
                          onClick={() => this.handleRemove(value._id)}
                          type="link"></Button>
                      </Tag>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
            <Button
              onClick={() => this.mergeLeadsToParent()}
              type="primary"
              style={{ marginTop: '10px' }}>
              Save Leads
            </Button>
          </Card>
        </Drawer>
        <Modal
          title="Change Vault Date"
          onCancel={async () => {
            this.setState({
              vaultDateModal: false,
            })
          }}
          visible={this.state.vaultDateModal}
          okText="SAVE"
          onOk={this.updateVaultDate}
          okButtonProps={
            {
              // loading: waLoader,
            }
          }>
          <DatePicker
            value={moment(this.state.selectedDate)}
            onChange={(date) => {
              this.setState({
                selectedDate: date,
              })
            }}
            format="DD-MMM-YYYY"
          />
        </Modal>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLeads)
