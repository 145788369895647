import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card } from 'antd'

import PageHeaderWrapper from '../../components/PageHeaderWrapper/index'
import { TableComp } from 'sz-react-utils'
import Request from '../../request'

class OtpList extends Component {
  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    return new Promise(async (resolve) => {
      let customQuery = {}

      if (params.mobile && params.mobile.length) {
        params.mobile = { $regex: `.*${params.mobile[0]}.*` }
      }

      if (params.deleted && params.deleted.length) {
        if (
          params.deleted.includes('true') &&
          params.deleted.includes('false')
        ) {
          delete params.deleted
          customQuery = {
            $or: [{ deleted: false }, { deleted: { $exists: false } }],
          }
        } else if (params.deleted.includes('false')) {
          params.deleted = false
        } else if (params.deleted.includes('true')) {
          params.deleted = { $exists: false }
        }
      }

      let data = await Request.getOtpList({
        ...params,
        customQuery,
      })

      this.setState({ count: data.total })
      resolve(data)
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      currentPage: 1,
    }
  }

  render() {
    let { currentPage } = this.state

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (_, __, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Phone Number',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
      },
      {
        title: 'Otp',
        dataIndex: 'otp',
        key: 'otp',
      },
      {
        title: 'Deleted',
        dataIndex: 'deleted',
        key: 'deleted',
        filters: [
          { text: 'True', value: 'true' },
          { text: 'False', value: 'false' },
        ],
        render: (val) => (val === false ? 'False' : 'True'),
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        searchDateName: 'createdAt',
        sorter: true,
        fixed: 'right',
        render: (_, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.createdAt
                  ? moment(record.createdAt).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
    ]

    return (
      <PageHeaderWrapper
        title={
          this.state.count ? `All Otp : ${this.state.count}` : `All Otp: 0`
        }>
        <Card bordered={true}>
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination,
            }}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpList)
