import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'

import Dashboard from './containers/dashboard/mainDashboard'

import AddLead from './containers/lead/add'
import AllLeads from './containers/lead/all'
import AddCall from './containers/call/add'
import AddProduct from './containers/product/add'
import AllCalls from './containers/call/all'
import AllProducts from './containers/product/all'
import AllFollowups from './containers/followup/all'
import FollowupCalendar from './containers/followup/calendarView'
import AddRO from './containers/salesMember/add'
import AllRO from './containers/salesMember/all'
import AddContact from './containers/contacts/add'
import AllContacts from './containers/contacts/all'
import AddQuote from './containers/quote/add'
import AddInvoice from './containers/invoice/add'
import AllQuotes from './containers/quote/all'
import AddOutsideQuote from './containers/quote/add/outsideIndiaQuote'
import AllOutsideQuotes from './containers/quote/all/outsideQuotes'
import AllTemplates from './containers/template/all/template'
import ViewLead from './containers/lead/all/viewLead'
import Task from './containers/taskManagement'
import AllCallLogs from './containers/callLogs/all'
import roLeads from './containers/lead/all/roLeadPage'
import roAllLeads from './containers/lead/all/roAllLeads'
import roWorkingLeads from './containers/lead/all/roWorkingLeads'
import ContactHistory from './containers/contacts/all/viewHistory'
import RedirectPage from './containers/contacts/all/redirect'
import AllRecordings from './containers/recordngs/all'
import AllCommunicationMails from './containers/communication/all'
import AddIP from './containers/ip/add'
import Graph from './containers/dashboard/graph'
import RODashboard from './containers/dashboard/roDashboard'
import AddCountry from './containers/country/add'
import AllCountry from './containers/country/all'
import AddState from './containers/state/add'
import AllState from './containers/state/all'
import AddCity from './containers/city/add'
import AllCity from './containers/city/all'
import AllVaultList from './containers/vault/all'
import UnQualifiedLeadsOfRO from './containers/lead/all/unQualifiedStatusLeadsForRO'
import UnQualifiedLeads from './containers/lead/all/unQualifiedLeads'
import AddTemplate from './containers/template/add'
import SingleROActivities from './containers/salesMember/all/singleROActivity'
import StateWiseLeads from './containers/lead/all/leadsByStates'
import ConflictsLeads from './containers/lead/all/conflictsLeads'
import AddInwardForm from './containers/dispatchDept/add/inwardForm'
import AddInward from './containers/inward/add/inwardForm'
import AddOutward from './containers/inward/add/outwardForm'
import AllInwardList from './containers/inward/all'
import AllStockList from './containers/inward/all/stockAll'
import AddOutwardForm from './containers/dispatchDept/add/outwardForm'
import AddDispatch from './containers/dispatchDept/add'
import DispatchedList from './containers/dispatchDept/all/dispatchList'
import AllInvoice from './containers/invoice/all'
import LoginHistory from './containers/salesMember/all/loginHistory'

import InwardOutwardList from './containers/dispatchDept/all'
import AllSMS from './containers/communication/all/smsList'
import AddDairyContact from './containers/dairyContact/add'
import AddDairyList from './containers/dairyContact/all'
import TodayFollowCalendar from './containers/followup/calendarLayout'
import AddHoliday from './containers/ip/add/holidays'
import OpenLeads from './containers/lead/all/openLeads'
import WonLeads from './containers/lead/all/wonLeads'
import AdminUnappprovalLeads from './containers/lead/all/adminApprovalLead'
import PurchaseVoucher from './containers/voucher/add/purchase'
import AdjustmentVoucher from './containers/voucher/add/adjustment'
import DispatchVoucher from './containers/voucher/add/dispatch'
import AllPurchaseVoucher from './containers/voucher/all/allPurchase'
import AllAdjustmentVoucher from './containers/voucher/all/allAdjustment'
import AllDispatchVoucher from './containers/voucher/all/allDispatch'
import ManufactureVoucher from './containers/voucher/add/manufacture'
import AllManufactureVoucher from './containers/voucher/all/allManufacture'
import AllFollowupsList from './containers/followup/newList'
import AllFollowupsListNew from './containers/followup/newFollowUpList'
import OtpList from './containers/otp/otpList'
import CallerLeads from './containers/lead/all/preQualified'
import ReportLeads from './containers/lead/all/leadReport'
import ServiceCenters from './containers/contacts/all/serviceCenters'
import ReplacementInwardList from './containers/dispatchDept/all/replacementInwardList'
import RepairList from './containers/dispatchDept/all/repairList'
import AddCategory from './containers/stock/category'
import AddStockProduct from './containers/stock/product/product'
import AllStockProduct from './containers/stock/product/productList'
import StockLedger from './containers/stock/ledger'
import ViewLedger from './containers/stock/product/viewLedger'
import AddComplaint from './containers/complaint/add'
import ComplaintList from './containers/complaint/all'
import FixedComplaintList from './containers/complaint/all/fixedComplaint'
import CloseComplaintList from './containers/complaint/all/closeComplaint'
import transferToAsc from './containers/complaint/all/transferToAsc'
import complaintToHO from './containers/complaint/all/complaintToHO'
import CloseComplaintListForAdmin from './containers/complaint/all/complaintListForAdmin'
import ThirtyDaysOldVideos from './containers/complaint/all/ThirtyDaysOldVideos'

import AddOrderDispatch from './containers/orderDispatch/add/index'

import OrderDispatchList from './containers/orderDispatch/all/list'
import OrderDetails from './containers/orderDispatch/all/orderDetails'
import DispatchItem from './containers/orderDispatch/all/dispatchItem'
import Carriers from './containers/ip/add/carriers'
import CallSkip from './containers/ip/add/callskip'

import MergeLeads from './containers/lead/all/mergeLeads'

const menu = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'dashboard',
    key: 'dashboard',
    title: 'Dashboard',
    homepage: true,
    component: Dashboard,
    authority: ['Admin', 'SuperAdmin', 'StaffMember'],
  },
  {
    path: '/graph',
    name: 'RO Dashboard',
    icon: 'bar-chart',
    authority: ['Admin', 'SuperAdmin', 'Manager'],
    title: 'RO Dashboard',
    component: Graph,
  },
  {
    path: '/RODashboard',
    name: 'RO Dashboard',
    icon: 'bar-chart',
    authority: ['RO'],
    title: 'Dashboard',
    component: RODashboard,
  },

  {
    path: '/RO',
    name: 'Relationship Officer',
    icon: 'customer-service',
    key: 'salesStaff',
    authority: ['SuperAdmin', 'Admin'],
    children: [
      {
        path: '/RO/add',
        name: 'Add Relationship Officer',
        title: 'Add Relationship Officer',
        component: AddRO,
      },
      {
        path: '/RO/all',
        name: 'All Relationship Officers',
        title: 'All Relationship Officers',
        component: AllRO,
      },
      {
        path: '/RO/edit-RO',
        name: 'Update RO',
        title: 'Update RO',
        key: 'editSalesStaff',
        dontShowOnMenu: true,
        component: AddRO,
      },
      {
        path: '/RO/single',
        name: 'Relationship Officer Activities',
        title: 'Relationship Officer Activities',
        component: SingleROActivities,
      },
      {
        path: '/RO/loginHistory',
        name: 'Login History',
        title: 'Login History',
        component: LoginHistory,
      },
    ],
  },
  {
    path: '/inward',
    name: 'Stock',
    icon: 'stock',
    key: 'inward',
    authority: ['Admin', 'SuperAdmin', 'Manager'],
    children: [
      {
        path: '/inward/add',
        name: 'Add Inward',
        key: 'add',
        title: 'Add Inward',
        component: AddInward,
        dontShowOnMenu: true,
        authority: ['Admin', 'SuperAdmin'],
        // dontShowOnMenu: true
      },
      {
        path: '/inward/edit',
        key: 'edit',
        title: 'Edit Inward',
        component: AddInward,
        authority: ['Admin', 'SuperAdmin'],
        dontShowOnMenu: true,
      },
      {
        path: '/outward/add',
        name: 'Add Outward',
        key: 'outward',
        title: 'Add outward',
        component: AddOutward,
        dontShowOnMenu: true,
        authority: ['Admin', 'SuperAdmin'],
        // dontShowOnMenu: true
      },
      {
        path: '/outward/edit',
        key: 'outwardEdit',
        title: 'Edit Inward',
        component: AddOutward,
        authority: ['Admin', 'SuperAdmin'],
        dontShowOnMenu: true,
      },
      {
        path: '/inward/inwardOutwardList',
        name: 'Inward-Outward',
        key: 'inwardOutwardList',
        title: 'Inward/Outward List',
        component: AllInwardList,
        // authority: ['Admin', 'SuperAdmin', 'Dispatch']
      },
      {
        path: '/inward/stock',
        name: 'Current Stock',
        key: 'stock',
        title: 'Stock',
        component: AllStockList,
        // authority: ['Admin', 'SuperAdmin', 'Dispatch']
      },
      {
        path: '/inward/category/add',
        name: 'Add Category',
        title: 'Add Category',
        component: AddCategory,
      },
      {
        path: '/inward/product/add',
        name: 'Add Product',
        title: 'Add Product',
        component: AddStockProduct,
      },
      {
        path: '/inward/product/edit',
        name: 'Add Product',
        title: 'Add Product',
        key: 'stockProductEdit',
        component: AddStockProduct,
        dontShowOnMenu: true,
      },
      {
        path: '/inward/product/all',
        name: 'Product List',
        title: 'Product List',
        component: AllStockProduct,
      },
      {
        path: '/inward/view/ledger',
        name: 'Ledger',
        title: 'Ledger',
        dontShowOnMenu: true,
        component: ViewLedger,
      },
    ],
  },
  {
    path: '/lead',
    name: 'Lead',
    icon: 'stock',
    key: 'lead',
    authority: ['Admin', 'SuperAdmin', 'Manager'],
    children: [
      {
        path: '/lead/add',
        name: 'Add Lead',
        title: 'Add Lead',
        component: AddLead,
        authority: ['Admin', 'SuperAdmin'],
        // dontShowOnMenu: true
      },
      {
        path: '/lead/detail',
        name: 'View Lead',
        title: 'View Lead',
        key: 'viewLead',
        component: ViewLead,
        dontShowOnMenu: true,
        authority: ['Admin', 'SuperAdmin', 'Manager', 'RO'],
      },
      {
        path: '/leads/all',
        name: 'All Leads',
        title: 'All Leads',
        key: 'adminAllLeads',
        component: AllLeads,
        authority: ['Admin', 'SuperAdmin', 'Manager'],
      },
      {
        path: '/lead/edit-lead',
        name: 'Update Lead',
        title: 'Update Lead',
        key: 'editLead',
        dontShowOnMenu: true,
        component: AddLead,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/leads/openLeads',
        name: 'All Open Leads',
        title: 'All Open Leads',
        key: 'adminOpenLeads',
        component: OpenLeads,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/leads/approvalLeads',
        name: 'All Unapproval Leads',
        title: 'All Unapproval Leads',
        key: 'adminUnapprovalLeads',
        component: AdminUnappprovalLeads,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/leads/wonLeads',
        name: 'All Won Leads',
        title: 'All Won Leads',
        key: 'wonLeads',
        component: WonLeads,
        authority: ['Admin', 'SuperAdmin'],
      },
      // {
      //     path: '/leads/mergedLeads',
      //     name: 'All Merge Leads',
      //     title: 'All Merge Leads',
      //     key: 'MergeLeads',
      //     component: MergeLeads,
      //     authority: ['Admin']
      // },
      {
        path: '/leads/unQualifiedLeads',
        name: 'All Unqualified Leads',
        title: 'All Unqualified Leads',
        key: 'adminUnqualifiedLeads',
        component: UnQualifiedLeads,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/leads/stateWiseLeads',
        name: 'State Wise Leads',
        title: 'State Wise Leads',
        key: 'stateWiseLeads',
        component: StateWiseLeads,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/lead/conflictsLead',
        name: 'Conflicts Lead',
        title: 'Conflicts Lead',
        component: ConflictsLeads,
        authority: ['Admin', 'SuperAdmin'],
        //dontShowOnMenu: true
      },
    ],
  },
  {
    path: '/roleads',
    name: 'Leads',
    icon: 'stock',
    key: 'rolead',
    authority: ['RO'],
    children: [
      {
        path: '/roleads/add',
        name: 'Add Lead',
        title: 'Add Lead',
        component: AddLead,
        // dontShowOnMenu: true
      },

      {
        path: '/roleads/all',
        name: 'All Leads',
        title: 'All Leads',
        key: 'roLeadsList',
        component: roAllLeads,
      },
      {
        path: '/roleads/working',
        name: 'All Working Leads',
        title: 'All Working Leads',
        key: 'roLeadsList',
        component: roWorkingLeads,
      },
      {
        path: '/roleads/wonLeads',
        name: 'All Won Leads',
        title: 'All Won Leads',
        key: 'wonLeads',
        component: WonLeads,
      },
      {
        path: '/roleads/unQualifiedLeads',
        name: 'All Unqualified Leads',
        title: 'All Unqualified Leads',
        key: 'unQualifiedLeads',
        component: UnQualifiedLeadsOfRO,
      },
      {
        path: '/roleads/allList',
        name: 'Current Leads',
        title: 'Current RO  Leads',
        component: roLeads,
      },
    ],
  },
  //   {
  //     path: '/vault',
  //     name: 'Vault',
  //     icon: 'stock',
  //     key: 'vault',
  //     authority: ['Admin', 'SuperAdmin', 'Manager'],
  //     children: [
  //       {
  //         path: '/vault/all',
  //         name: 'Vault List',
  //         title: 'Vault List',
  //         component: AllVaultList,
  //         authority: ['Admin', 'SuperAdmin'],
  //         // dontShowOnMenu: true
  //       },
  //     ],
  //   },
  {
    path: '/contact',
    name: 'Contact',
    icon: 'user',
    key: 'contact',
    authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
    children: [
      {
        path: '/contact/add',
        name: 'Add Contact',
        title: 'Add Contact',
        component: AddContact,
        authority: ['Admin', 'RO', 'SuperAdmin'],
      },
      {
        path: '/contact/all',
        name: 'All Contacts',
        title: 'All Contacts',
        component: AllContacts,
        authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
      },
      {
        path: '/contact/edit',
        name: 'Update Contact',
        title: 'Update Contact',
        key: 'editContact',
        dontShowOnMenu: true,
        component: AddContact,
        authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
      },
      {
        path: '/contact/history',
        name: 'Contact History',
        title: 'Contact History',
        key: 'viewHistory',
        dontShowOnMenu: true,
        component: ContactHistory,
        authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
      },
      {
        path: '/contact/uploadSuccess',
        name: 'Redirect',
        title: 'Redirect',
        key: 'redirect',
        dontShowOnMenu: true,
        component: RedirectPage,
        authority: ['Admin', 'RO', 'SuperAdmin'],
      },
    ],
  },
  {
    path: '/quote',
    name: 'Quote',
    icon: 'unordered-list',
    key: 'quote',
    authority: ['Admin', 'SuperAdmin', 'RO', 'Manager'],
    children: [
      {
        path: '/quote/add',
        name: 'Add Quote',
        title: 'Add Quote',
        key: 'addQuote',
        component: AddQuote,
        authority: ['Admin', 'RO', 'SuperAdmin'],
      },
      {
        path: '/quote/outsideQuote',
        name: 'Add USD Quote',
        title: 'Add USD Quote',
        key: 'addOutsideQuotes',
        component: AddOutsideQuote,
        authority: ['Admin', 'RO', 'SuperAdmin'],
      },
      {
        path: '/quote/outsideEditQuote',
        name: 'Edit Quote',
        title: 'Edit Quote',
        key: 'editOutsideQuotes',
        component: AddOutsideQuote,
        dontShowOnMenu: true,
        authority: ['Admin', 'RO', 'SuperAdmin'],
      },
      {
        path: '/quote/all',
        name: 'All Quotes',
        title: 'All Quotes',
        component: AllQuotes,
        authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
      },
      {
        path: '/quote/outside/all',
        name: 'All USD Quotes',
        title: 'All USD Quotes',
        component: AllOutsideQuotes,
        authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
      },
      {
        path: '/quote/edit',
        name: 'Update Quote',
        title: 'Update Quote',
        key: 'editQuote',
        dontShowOnMenu: true,
        component: AddQuote,
        authority: ['Admin', 'RO', 'SuperAdmin', 'Manager'],
      },
      // {
      //   'path': '/quote/unapproved',
      //   'authority': [
      //     'Admin',
      //     'SuperAdmin'
      //   ],
      //   'name': 'Unapproved Quote',
      //   'title': 'Unapproved Quote',
      //   'key': 'unapprovedQuote',
      //   'component': unApprovedQuote
      // }
    ],
  },
  //   {
  //     path: '/invoice',
  //     name: 'Invoice',
  //     icon: 'file-done',
  //     key: 'invoice',
  //     authority: ['Admin', 'SuperAdmin', 'RO'],
  //     children: [
  //       {
  //         path: '/invoice/add',
  //         name: 'Add Invoice',
  //         title: 'Add Invoice',
  //         key: 'addInvoice',
  //         component: AddInvoice,
  //       },
  //       {
  //         path: '/invoice/edit',
  //         name: 'Update Invoice',
  //         title: 'Update Invoice',
  //         key: 'editInvoice',
  //         dontShowOnMenu: true,
  //         component: AddInvoice,
  //       },
  //       {
  //         path: '/invoice/all',
  //         name: 'All Invoices',
  //         title: 'All Invoices',
  //         component: AllInvoice,
  //       },
  //     ],
  //   },

  {
    path: '/product',
    name: 'Product For Sale',
    icon: 'book',
    key: 'product',
    authority: ['Admin', 'SuperAdmin'],
    children: [
      {
        path: '/product/add',
        name: 'Add Product',
        title: 'Add Product',
        component: AddProduct,
      },
      {
        path: '/product/all',
        name: 'All Products',
        title: 'All Products',
        component: AllProducts,
      },
      {
        key: 'editProduct',
        dontShowOnMenu: true,
        path: '/product/edit/:id',
        name: 'Edit Product',
        title: 'Edit Product',
        component: AddProduct,
      },
    ],
  },

  {
    path: '/followUp',
    name: 'Followup',
    icon: 'plus-square',
    key: 'followup',
    authority: ['Admin', 'SuperAdmin', 'RO', 'Manager'],
    children: [
      {
        path: '/followup/all',
        name: 'All Followups',
        title: 'All Followups',
        component: AllFollowups,
        key: 'allFollowups',
        authority: ['Admin', 'SuperAdmin', 'RO'],
      },
      {
        path: '/followup/calendar',
        name: 'Followup Calendar',
        authority: ['RO'],
        title: 'All Followups',
        component: FollowupCalendar,
      },
      {
        path: '/followup/calenderView',
        name: 'Today Followup',
        title: 'Today Followup',
        component: TodayFollowCalendar,
        key: 'todayFollow',
        authority: ['Admin', 'SuperAdmin', 'RO'],
      },
      {
        path: '/followup/list',
        name: 'All Followups',
        title: 'All Followups',
        component: AllFollowupsList,
        key: 'allFollowupsList',
        authority: ['Admin', 'SuperAdmin', 'Manager'],
      },
    ],
  },
  {
    path: '/templates',
    name: 'Templates',
    icon: 'mail',
    key: 'templates',
    authority: ['Admin', 'SuperAdmin'],
    children: [
      {
        path: '/templates/add',
        name: 'Add Template',
        authority: ['Admin', 'SuperAdmin'],
        icon: 'folder',
        title: 'Add Template',
        component: AddTemplate,
      },
      {
        path: '/templates/all',
        name: 'All Templates',
        icon: 'mail',
        key: 'templates',
        component: AllTemplates,
        title: 'All Templates',
        authority: ['Admin', 'SuperAdmin'],
      },
    ],
  },
  //   {
  //     path: '/task',
  //     name: 'Task Management',
  //     icon: 'database',
  //     key: 'task',
  //     component: Task,
  //     authority: ['Admin', 'SuperAdmin'],
  //   },

  {
    path: '/communication',
    name: 'Communication',
    icon: 'appstore',
    key: 'communication',
    authority: ['Admin', 'SuperAdmin', 'RO'],
    children: [
      {
        path: '/communication/all/calls',
        name: 'Operator Calls',
        icon: 'phone',
        key: 'call',
        title: 'Operator Calls',
        component: AllCalls,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/communication/callLogs',
        name: 'All Call Logs',
        icon: 'mobile',
        key: 'callLogs',
        component: AllCallLogs,
        title: 'All Call Logs',
        // 'dontShowOnMenu': true,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/communication/recordings',
        name: 'All Recordings',
        title: 'All Recordings',
        icon: 'play-circle',
        key: 'recordings',
        component: AllRecordings,
        // 'dontShowOnMenu': true,
        authority: ['Admin', 'SuperAdmin'],
      },
      {
        path: '/communication/communication',
        name: 'All Mails',
        icon: 'mail',
        key: 'allMails',
        title: 'Mails',
        component: AllCommunicationMails,
        authority: ['Admin', 'SuperAdmin', 'RO'],
      },
      {
        path: '/communication/sms',
        name: 'All SMS',
        icon: 'message',
        key: 'allSMS',
        title: 'SMS',
        component: AllSMS,
        authority: ['Admin', 'SuperAdmin'],
      },
    ],
  },

  {
    path: '/setting',
    name: 'Settings',
    title: 'Settings',
    key: 'settings',
    icon: 'setting',
    authority: ['Admin', 'SuperAdmin'],
    children: [
      {
        path: '/setting/ip/add',
        name: 'IP Address',
        icon: 'appstore',
        key: 'ipAddress',
        authority: ['Admin', 'SuperAdmin'],
        title: 'Add IP',
        component: AddIP,
      },
      {
        path: '/setting/holiday/add',
        name: 'Add Holiday',
        icon: 'holiday',
        key: 'holiday',
        authority: ['Admin', 'SuperAdmin'],
        title: 'Add Holiday',
        component: AddHoliday,
      },
      {
        path: '/setting/carriers/add',
        name: 'Add Carriers',
        icon: 'holiday',
        key: 'holiday',
        authority: ['Admin', 'SuperAdmin'],
        title: 'Add Carriers',
        component: Carriers,
      },
      {
        path: '/setting/callskip/add',
        name: 'Call Skip',
        icon: 'holiday',
        key: 'callSkip',
        authority: ['Admin', 'SuperAdmin'],
        title: 'Call Skip',
        component: CallSkip,
      },
    ],
  },

  {
    path: '/graphCountUrl/:officerId/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin', 'Manager'],
    title: 'Leads',
    key: 'countUrl',
    dontShowOnMenu: true,
    component: AllLeads,
  },
  {
    path: '/callCountUrl/:officerId/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin', 'Manager'],
    title: 'Calls',
    key: 'callUrl',
    dontShowOnMenu: true,
    component: AllCallLogs,
  },
  {
    path: '/recordingCountUrl/:officerId/:lte/:gte',
    authority: ['Admin', 'SuperAdmin', 'Manager'],
    title: 'Recording',
    key: 'recordingUrl',
    dontShowOnMenu: true,
    component: AllRecordings,
  },

  {
    path: '/country',
    name: 'Country',
    icon: 'flag',
    key: 'country',
    authority: ['SuperAdmin', 'Admin'],
    children: [
      {
        path: '/country/add',
        name: 'Add Country',
        title: 'Add Country',
        icon: 'country',
        component: AddCountry,
      },
      {
        path: '/country/countryList',
        name: 'All Country',
        icon: 'country',
        key: 'allCountry',
        title: 'All Country',
        component: AllCountry,
        authority: ['Admin', 'SuperAdmin'],
      },
    ],
  },

  {
    path: '/state',
    name: 'State',
    icon: 'team',
    key: 'state',
    authority: ['SuperAdmin', 'Admin'],
    children: [
      {
        path: '/state/add',
        name: 'Add State',
        title: 'Add State',
        icon: 'state',
        component: AddState,
      },
      {
        path: '/state/stateList',
        name: 'All State',
        icon: 'state',
        key: 'allState',
        title: 'All State',
        component: AllState,
        authority: ['Admin', 'SuperAdmin'],
      },
    ],
  },
  {
    path: '/city',
    name: 'City',
    icon: 'table',
    key: 'city',
    authority: ['SuperAdmin', 'Admin'],
    children: [
      {
        path: '/city/add',
        name: 'Add City',
        title: 'Add City',
        icon: 'state',
        component: AddCity,
      },
      {
        path: '/city/cityList',
        name: 'All City',
        icon: 'state',
        key: 'allCity',
        title: 'All City',
        component: AllCity,
        authority: ['Admin', 'SuperAdmin'],
      },
    ],
  },

  {
    path: '/states/:stateName/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin'],
    title: 'States',
    key: 'statesLeadsUrl',
    dontShowOnMenu: true,
    component: AllLeads,
  },
  {
    path: '/officerStates/:officerId/:stateName/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin'],
    title: 'States',
    key: 'officerStatesUrl',
    dontShowOnMenu: true,
    component: AllLeads,
  },

  {
    path: '/dispatch',
    name: 'Inward-Outward Security',
    title: 'Inward-Outward Security',
    icon: 'stock',
    key: 'dispatch',
    authority: ['SuperAdmin', 'Admin', 'Dispatch'],
    children: [
      {
        path: '/dispatch/inward/add',
        name: 'Add Inward Form',
        title: 'Inward Form',
        component: AddInwardForm,
      },
      {
        path: '/dispatch/outward/add',
        name: 'Add Outward Form',
        title: 'Add Outward Form',
        component: AddOutwardForm,
      },
      {
        path: '/dispatch/inward/edit',
        name: 'Update Inward Form',
        title: 'Update Inward Form',
        key: 'editInwardForm',
        dontShowOnMenu: true,
        component: AddInwardForm,
      },
      {
        path: '/dispatch/outward/edit',
        name: 'Update Outward Form',
        title: 'Update Outward Form',
        key: 'editOutwardForm',
        dontShowOnMenu: true,
        component: AddOutwardForm,
      },
      {
        path: '/dispatch/inwardOutwardList',
        name: 'Inward/Outward List',
        key: 'inwardOutwardList',
        title: 'Inward/Outward List',
        component: InwardOutwardList,
        authority: ['Admin', 'SuperAdmin', 'Dispatch'],
      },
      {
        path: '/dispatch/replacementList',
        name: 'Replacement List',
        key: 'replacementList',
        title: 'Replacement List',
        component: ReplacementInwardList,
        authority: ['Admin', 'SuperAdmin', 'Dispatch'],
      },

      {
        path: '/dispatch/repairList',
        name: 'Repair List',
        key: 'repairList',
        title: 'Repair List',
        component: RepairList,
        authority: ['Admin', 'SuperAdmin', 'Dispatch'],
      },

      {
        path: '/dispatch/add',
        name: 'Add Dispatch',
        key: 'addDispatch',
        title: 'Add Dispatch',
        component: AddDispatch,
        authority: ['Admin', 'SuperAdmin', 'Dispatch'],
      },
      {
        path: '/dispatch/edit',
        name: 'Update Dispatch',
        title: 'Update Dispatch',
        key: 'editDispatchForm',
        dontShowOnMenu: true,
        component: AddDispatch,
        authority: ['Admin', 'SuperAdmin', 'Dispatch'],
      },
      {
        path: '/dispatch/list',
        name: 'Dispatch List',
        key: 'dispatchList',
        title: 'Dispatch List',
        component: DispatchedList,
        authority: ['Admin', 'SuperAdmin', 'Dispatch'],
      },
    ],
  },

  //   {
  //     path: '/dairyContact',
  //     name: 'Dairy Contact',
  //     title: 'Dairy Contact',
  //     icon: 'contacts',
  //     key: 'dairyContact',
  //     authority: ['SuperAdmin', 'Admin', 'RO', 'Manager'],
  //     children: [
  //       {
  //         path: '/dairyContact/add',
  //         name: 'Add Dairy Contact',
  //         title: 'Add Dairy Contact',
  //         component: AddDairyContact,
  //         authority: ['SuperAdmin', 'Admin', 'RO'],
  //       },
  //       {
  //         path: '/dairyContact/edit',
  //         name: 'Update Dairy Contact',
  //         title: 'Update Dairy Contact',
  //         key: 'editDairyContact',
  //         dontShowOnMenu: true,
  //         component: AddDairyContact,
  //         authority: ['SuperAdmin', 'Admin', 'RO', 'Manager'],
  //       },
  //       {
  //         path: '/dairyContact/list',
  //         name: 'Dairy Contact List',
  //         key: 'dairyContactList',
  //         title: 'Dairy Contact List',
  //         component: AddDairyList,
  //         authority: ['Admin', 'SuperAdmin', 'RO', 'Manager'],
  //       },
  //     ],
  //   },
  //   {
  //     path: '/voucher',
  //     name: 'Voucher',
  //     icon: 'switcher',
  //     key: 'voucher',
  //     title: 'Voucher',
  //     authority: ['Admin', 'SuperAdmin', 'RO'],
  //     children: [
  //       {
  //         path: '/voucher/purchase/add',
  //         name: 'Add Purchase Voucher',
  //         title: 'Add Purchase Voucher',
  //         component: PurchaseVoucher,
  //       },
  //       {
  //         path: '/voucher/purchase/edit',
  //         name: 'Add Purchase Voucher',
  //         title: 'Add Purchase Voucher',
  //         key: 'editPurchase',
  //         dontShowOnMenu: true,
  //         component: PurchaseVoucher,
  //       },
  //       {
  //         path: '/voucher/adjustment/add',
  //         name: 'Add Adjustment Voucher',
  //         title: 'Add Adjustment Voucher',
  //         component: AdjustmentVoucher,
  //       },
  //       {
  //         path: '/voucher/adjustment/edit',
  //         name: 'Add Adjustment Voucher',
  //         title: 'Add Adjustment Voucher',
  //         key: 'editAdjustment',
  //         dontShowOnMenu: true,
  //         component: AdjustmentVoucher,
  //       },
  //       {
  //         path: '/voucher/dispatch/add',
  //         name: 'Add Dispatch Voucher',
  //         title: 'Add Dispatch Voucher',
  //         component: DispatchVoucher,
  //       },
  //       {
  //         path: '/voucher/dispatch/edit',
  //         name: 'Edit Dispatch Voucher',
  //         title: 'Edit Dispatch Voucher',
  //         key: 'editDispatch',
  //         dontShowOnMenu: true,
  //         component: DispatchVoucher,
  //       },
  //       {
  //         path: '/voucher/manufacture/edit',
  //         name: 'Edit Manufacture voucher',
  //         title: 'Edit Manufacture voucher',
  //         key: 'editManufacture',
  //         dontShowOnMenu: true,
  //         component: ManufactureVoucher,
  //       },
  //       {
  //         path: '/voucher/manufacture/add',
  //         name: 'Add Manufacture Voucher',
  //         title: 'Add Manufacture Voucher',
  //         component: ManufactureVoucher,
  //       },
  //       {
  //         path: '/voucher/purchase/all',
  //         name: 'All Purchase Vouchers',
  //         title: 'All Purchase Vouchers',
  //         component: AllPurchaseVoucher,
  //       },
  //       {
  //         path: '/voucher/dispatch/all',
  //         name: 'All Dispatch Vouchers',
  //         title: 'All Dispatch Vouchers',
  //         component: AllDispatchVoucher,
  //       },
  //       {
  //         path: '/voucher/adjustment/all',
  //         name: 'All Adjustment Vouchers',
  //         title: 'All Adjustment Vouchers',
  //         component: AllAdjustmentVoucher,
  //       },
  //       {
  //         path: '/voucher/manufacture/all',
  //         name: 'All Manufacture Vouchers',
  //         title: 'All Manufacture Vouchers',
  //         component: AllManufactureVoucher,
  //       },
  //     ],
  //   },
  {
    path: '/status/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin'],
    title: 'Status',
    key: 'statusUrl',
    dontShowOnMenu: true,
    component: AllLeads,
  },
  {
    path: '/ro/task',
    name: 'Task Management',
    icon: 'database',
    key: 'ROTask',
    component: Task,
    authority: ['RO'],
  },
  {
    path: '/lead/add/executive',
    name: 'Add Lead',
    title: 'Add Lead',
    icon: 'database',
    key: 'addLeadExecutive',
    component: AddLead,
    authority: ['Lead Executive'],
  },
  {
    path: '/followups/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin'],
    title: 'Followups',
    key: 'followUpsUrl',
    dontShowOnMenu: true,
    component: AllFollowupsListNew,
  },
  {
    path: '/taskManagment/:lte/:gte/:value',
    authority: ['Admin', 'SuperAdmin'],
    title: 'Task',
    key: 'taskUrl',
    dontShowOnMenu: true,
    component: Task,
  },
  //   {
  //     path: '/caller/leads',
  //     name: 'All Leads',
  //     title: 'All Leads',
  //     key: 'callerLeads',
  //     icon: 'setting',
  //     authority: ['Admin', 'SuperAdmin', 'Caller'],
  //     component: CallerLeads,
  //   },
  {
    path: '/report/leads',
    name: 'Report ',
    title: 'Report',
    key: 'report',
    icon: 'setting',
    authority: ['Admin', 'SuperAdmin'],
    dontShowOnMenu: true,
    component: ReportLeads,
  },
  {
    path: '/openStage/:lte/:value',
    authority: ['Admin', 'SuperAdmin'],
    title: 'Open Stage',
    key: 'openStageUrl',
    dontShowOnMenu: true,
    component: OpenLeads,
  },
  {
    path: '/serviceCenters',
    authority: ['Admin', 'SuperAdmin', 'RO'],
    title: 'Service Centers',
    name: 'Service Centers',
    key: 'serviceCenters',
    component: ServiceCenters,
  },
  // {
  //     path: '/stock',
  //     name: 'Stock',
  //     icon: 'stock',
  //     key: 'stock',
  //     title: 'Stock',
  //     authority: ['Admin', 'SuperAdmin'],
  //     children: [
  //         {
  //             path: '/stock/category/add',
  //             name: 'Add Category',
  //             title: 'Add Category',
  //             component: AddCategory
  //         },
  //         {
  //             path: '/stock/product/add',
  //             name: 'Add Product',
  //             title: 'Add Product',
  //             component: AddStockProduct
  //         },
  //         {
  //             path: '/stock/product/edit',
  //             name: 'Add Product',
  //             title: 'Add Product',
  //             key: 'stockProductEdit',
  //             component: AddStockProduct,
  //             dontShowOnMenu: true
  //         },
  //         {
  //             path: '/stock/product/all',
  //             name: 'Product List',
  //             title: 'Product List',
  //             component: AllStockProduct
  //         },
  //         {
  //             path: '/stock/view/ledger',
  //             name: 'Ledger',
  //             title: 'Ledger',
  //             dontShowOnMenu: true,
  //             component: ViewLedger
  //         }

  //     ]
  // },
  {
    path: '/complaint',
    name: 'Complaint',
    icon: 'schedule',
    key: 'Complaint',
    title: 'Complaint',
    authority: ['Admin', 'SuperAdmin', 'RO'],
    children: [
      {
        path: '/complaint/add',
        name: 'Add Complaint',
        title: 'Add Complaint',
        component: AddComplaint,
        authority: ['RO', 'Admin'],
      },
      {
        path: '/complaint/all',
        name: 'Open Complaint List',
        title: 'Open Complaint List',
        component: ComplaintList,
        authority: ['RO'],
      },
      {
        path: '/complaint/fixedComplaint',
        name: 'Fixed Complaint List',
        title: 'Fixed Complaint List',
        component: FixedComplaintList,
        authority: ['RO'],
      },
      {
        path: '/complaint/transferToAsc',
        name: 'Transfer To ASC List',
        title: 'Transfer To ASC List',
        component: transferToAsc,
        authority: ['RO'],
      },
      {
        path: '/complaint/complaintToHO',
        name: 'Compalint To Head Office',
        title: 'Compalint To Head Office',
        component: complaintToHO,
        authority: ['RO'],
      },
      {
        path: '/complaint/closeComplaint',
        name: 'Close Complaint List',
        title: 'Close Complaint List',
        component: CloseComplaintList,
        authority: ['RO'],
      },
      {
        path: '/complaint/closeComplaintForAdmin',
        name: 'Complaint List',
        key: 'list',
        title: 'Complaint List',
        component: CloseComplaintListForAdmin,
        authority: ['Admin'],
      },
      {
        path: '/complaint/ThirtyDaysOldVideos',
        name: 'Thirty Days Old Videos',
        title: 'Thirty Days Old Videos',
        component: ThirtyDaysOldVideos,
        authority: ['Admin'],
      },
    ],
  },
  {
    path: '/orderDispatch',
    name: 'Order Dispatch',
    icon: 'schedule',
    key: 'orderDispatch',
    title: 'Order Dispatch',
    authority: ['Admin', 'SuperAdmin', 'RO'],
    children: [
      {
        path: '/orderDispatch/add',
        name: 'Add Order Dispatch',
        title: 'Add Order Dispatch',
        component: AddOrderDispatch,
        authority: ['RO', 'Admin'],
      },
      {
        path: '/orderDispatch/edit',
        name: 'Edit Order Dispatch',
        key: 'editOrderDispatch',
        title: 'Edit Order Dispatch',
        component: AddOrderDispatch,
        dontShowOnMenu: true,
        authority: ['RO', 'Admin'],
      },
      {
        path: '/orderDispatch/list',
        name: 'Order List',
        key: 'list',
        title: 'Order List',
        component: OrderDispatchList,
        authority: ['RO', 'Admin'],
      },
      {
        path: '/orderDispatch/orderDetails',
        name: 'Order Details',
        title: 'Order Details',
        key: 'orderDetails',
        dontShowOnMenu: true,
        component: OrderDetails,
        authority: ['RO', 'Admin'],
      },
      {
        path: '/orderDispatch/dispatchItem',
        name: 'Order Dispatch',
        title: 'Order Dispatch',
        key: 'orderDispatchItem',
        dontShowOnMenu: true,
        component: DispatchItem,
        authority: ['RO', 'Admin'],
      },
    ],
  },
  {
    path: '/otp',
    name: 'Otp',
    icon: 'phone',
    key: 'otp',
    title: 'Otp',
    authority: ['Admin'],
    children: [
      {
        path: '/otp/list',
        name: 'Otp List',
        key: 'list',
        title: 'Otp List',
        component: OtpList,
        authority: ['Admin'],
      },
    ],
  },
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, (p) => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {
  let keyArr = keyString.split('.')

  let val = _.find(menu, (p) => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu
